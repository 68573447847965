.GalleryItem {
    position: relative;
    border: 5px solid #2196F3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    height: 300px;
}

.GalleryItem__Loading {
    color: #2196F3;
}

.GalleryItem__Img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
    transition: transform 300ms ease-out;
}

.GalleryItem:hover .GalleryItem__Img {
    transform: scale(1.2) rotate(20deg);
}

.GalleryItem__Title {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    background-color: #2196F3;
    color: #FFFFFF;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(100%);
    transition: transform 300ms ease-out;
}

.GalleryItem:hover .GalleryItem__Title,
.GalleryItem--loading .GalleryItem__Title {
    display: block;
    transform: translateY(0%);
}

@media (hover: none) {
    .GalleryItem__Title {
        display: block;
        transform: translateY(0%);
    }
}
