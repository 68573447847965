.BreedPills {
    background-color: #eaeaea;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.BreedPills__Item {
    display: inline-block;
    padding: 5px;
    background-color: #2196F3;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}
