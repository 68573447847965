.BreedFilter {
    background-color: #eaeaea;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.BreedFilter__Container {
    background-color: #eaeaea;
}

@media (max-width: 767px) {
    .BreedFilter__Container {
        display: none;
    }

    .BreedFilter__Container--display {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        z-index: 100;
        padding: 10px;
    }
}
