.BreedFilterItem {
    display: block;
}

.BreedFilterItem>.BreedFilterItem {
    margin-left: 20px;
}


.BreedFilterItem__Label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.BreedFilterItem__Label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.BreedFilterItem__Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    /* background-color: #eee; */
    background-color: #fff;
}

/* On mouse-over, add a grey background color */
.BreedFilterItem__Label:hover input~.BreedFilterItem__Checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.BreedFilterItem__Label input:checked~.BreedFilterItem__Checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.BreedFilterItem__Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.BreedFilterItem__Label input:checked~.BreedFilterItem__Checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.BreedFilterItem__Label .BreedFilterItem__Checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
